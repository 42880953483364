import isEmpty from "../validation/is-empty";
import setAuthToken from "../utils/setAuthToken";

import { SET_CURRENT_USER } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {},
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_USER:
      if (!isEmpty(payload)) {
        return {
          ...state,
          isAuthenticated: !isEmpty(payload),
          user: payload,
        };
      } else {
        localStorage.removeItem("jwtToken");
        setAuthToken(false);
        return {
          ...state,
          isAuthenticated: false,
          user: payload,
        };
      }
    default:
      return state;
  }
}
