import React from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { Input } from "@chakra-ui/react";

class BuyerDetails extends React.Component {
  state = {
    buyer: {
      id: 0,
      buyerName: "",
      buyerMobile: "",
      buyerEmail: "",
      buyerQid: "",
      buyerSignature: "",
      PaymentMethod: { id: 0, name: "" },
      User: { id: 0, name: "" },
      installmentYears: "",
      totalInstallments: "",
      paymentScheduleMonths: "",
    },
    signature: "",
  };
  onEntering = () => {
    console.log(this.props);
    this.setState({ buyer: this.props.id }, () => {
      Axios.get(`/api/bookings/booking-signature/${this.state.buyer.id}`)
        .then((res) => {
          const signature = res.data;
          this.setState({ signature });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  onExited = () => {
    this.setState({
      buyer: {
        id: 0,
        buyerName: "",
        buyerMobile: "",
        buyerEmail: "",
        buyerQid: "",
        buyerSignature: "",
        PaymentMethod: { id: 0, name: "" },
        User: { id: 0, name: "" },
        installmentYears: "",
        totalInstallments: "",
        paymentScheduleMonths: "",
      },
      signature: "",
    });
  };
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntering={this.onEntering}
        onExited={this.onExited}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Buyer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Input
                    value={this.state.buyer.buyerName}
                    contentEditable={false}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Mobile</Form.Label>
                  <Input
                    value={this.state.buyer.buyerMobile}
                    contentEditable={false}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Input
                    value={this.state.buyer.buyerEmail}
                    contentEditable={false}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>QID</Form.Label>
                  <Input
                    value={this.state.buyer.buyerQid}
                    contentEditable={false}
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Payment Method</Form.Label>
                  <Input
                    value={this.state.buyer.PaymentMethod.name}
                    // value={this.state.buyer.paymentMethod}
                    contentEditable={false}
                  />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Installment Period (Years)</Form.Label>
                  <Input
                    value={this.state.buyer.installmentYears}
                    contentEditable={false}
                  />
                  {/* <Input
                    value={this.state.buyer.paymentScheduleMonths}
                    contentEditable={false}
                  /> */}
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group>
                  <Form.Label>Total Installments</Form.Label>
                  <Input
                    value={this.state.buyer.totalInstallments}
                    contentEditable={false}
                  />
                  {/* <Input
                    value={this.state.buyer.paymentScheduleMonths}
                    contentEditable={false}
                  /> */}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Signature</Form.Label>
                  <img
                    src={`data:image/jpeg;base64,${this.state.signature}`}
                    alt=""
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default BuyerDetails;
