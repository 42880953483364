import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Landing extends Component {
    state = {  }
    render() {
        return (
            <Redirect to="/dashboard" />
        );
    }
}

export default Landing;