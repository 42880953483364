import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import NumberInputField from "../menu/NumberInputField";
import Axios from "axios";

function CreateEditReservationFee({ id, show, onHide, onCreate, onEdit }) {
  const [formData, setFormData] = useState({
    amount: 0,
  });
  const [formErrors, setFormErrors] = useState({});

  const onClick = (e) => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    Axios.post("/api/reservation-fees", formData)
      .then((res) => {
        onCreate(res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const handleEdit = () => {
    Axios.put("/api/reservation-fees/" + id, formData)
      .then((res) => {
        onEdit(id, res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const onEntering = () => {
    if (id) {
      Axios.get(`/api/reservation-fees/${id}`)
        .then((res) => {
          console.log(res.data);
          delete res.data.id;
          setFormData({ ...res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onExited = () => {
    setFormData({
      amount: 0,
    });
    setFormErrors({});
  };

  const onChangeNumber = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEntering={onEntering}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit" : "Create"} Reservation Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <NumberInputField
            field="amount"
            label="Amount"
            value={formData.amount}
            onChange={onChangeNumber}
            errors={formErrors}
            commas={true}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{id ? "Edit" : "Create"}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateEditReservationFee;
