import React from "react";
import { Form } from "react-bootstrap";
import { Input, Text } from "@chakra-ui/react";

function TextInputField({ field, label, value, onChange, errors }) {
  const error = errors ? errors[field] : "";
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Input
        value={value}
        onChange={(e) => onChange(field, e.target.value)}
        borderColor={error ? "red" : "lightgray"}
      />
      {error ? (
        <Text color="red" fontSize="12px">
          {error}
        </Text>
      ) : (
        <></>
      )}
    </Form.Group>
  );
}

export default TextInputField;
