import React from "react";
import { Text } from "@chakra-ui/react";
import Select from "react-select";
import { Form } from "react-bootstrap";

function DropdownField({
  field,
  label,
  value,
  options,
  disabled,
  labelField,
  valueField,
  onChange,
  callback,
  errors,
}) {
  const error = errors ? (field ? errors[field] : errors[valueField]) : "";
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Select
        value={value}
        options={options}
        onChange={(item) =>
          onChange(field ? field : valueField, valueField, item, callback)
        }
        getOptionLabel={(n) => n[labelField]}
        getOptionValue={(n) => n[valueField]}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: error ? "red" : "lightgray",
          }),
        }}
        isDisabled={disabled}
      />
      {error ? (
        <Text color="red" fontSize="12px">
          {error}
        </Text>
      ) : (
        <></>
      )}
    </Form.Group>
  );
}

export default DropdownField;
