import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { Form, Button } from "react-bootstrap";
import { Box, Container, SimpleGrid, Spinner } from "@chakra-ui/react";
import StatCard from "./StatCard";
import CardContainer from "./CardContainer";
import { RiNewspaperLine } from "react-icons/ri";
import { MdConstruction } from "react-icons/md";
import { GiRoad } from "react-icons/gi";
import { RiNumbersLine } from "react-icons/ri";
import Axios from "axios";
import { roleTypes } from "../../config";
import { getNormalFormattedDate } from "../../utils";

const iconSize = 56;

function Home({ auth }) {
  let from = new Date();
  from.setMonth(from.getMonth() - 1);
  from = from.setHours(0, 0, 0);
  let to = new Date();
  to = to.setHours(23, 59, 59);

  const [dateFrom, setDateFrom] = useState(getNormalFormattedDate(from));
  const [dateTo, setDateTo] = useState(getNormalFormattedDate(to));

  const [loadingStats, setLoadingStats] = useState(false);
  const [loadingCompanyComparison, setLoadingCompanyComparison] =
    useState(false);

  const [stats, setStats] = useState({
    soldProperties: 0,
    saleByInterested: 0,
    availableProperties: 0,
    totalProperties: 0,
  });
  const [options, setOptions] = useState({
    // chart: {
    //   id: "basic-line",
    // },
    // xaxis: {
    //   categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
    // },
  });
  const [series, setSeries] = useState([
    // {
    //   name: "Qetaifan",
    //   data: [30, 40, 45, 50, 49, 60, 70, 91],
    // },
    // {
    //   name: "JRE",
    //   data: [20, 50, 35, 70, 39, 80, 40, 101],
    // },
  ]);

  useEffect(() => {
    getStats(); //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStats = () => {
    setLoadingStats(true);
    Axios.post("/api/dashboard/stats", { userId: auth.user.id })
      .then((res) => {
        setStats(res.data);
        setLoadingStats(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingStats(false);
      });
  };

  const getCompanyComparison = () => {
    setLoadingCompanyComparison(true);
    Axios.post("/api/dashboard/company-comparison", { dateFrom, dateTo })
      .then((res) => {
        const { dates, data } = res.data;
        // setOptions({ xaxis: dates });
        setOptions({
          chart: {
            id: "basic-line",
          },
          markers: {
            size: 5,
          },
          xaxis: {
            categories: dates,
          },
        });
        setSeries(data);
        setLoadingCompanyComparison(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingCompanyComparison(false);
      });
  };

  return (
    <Container maxW="container.xl" pt="20px">
      {!loadingStats ? (
        <>
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 4 }}
            spacingX={{ base: "0px", sm: "20px" }}
            spacingY={{ base: "20px" }}
            mb="20px"
          >
            <StatCard
              title="Sold Properties"
              color="tomato"
              value={stats.soldProperties}
              icon={<RiNewspaperLine size={iconSize} color="white" />}
              onClick={() => {}}
            />
            <StatCard
              title="Sale by Interested"
              value={stats.saleByInterested}
              color="dodgerBlue"
              icon={<MdConstruction size={iconSize} color="white" />}
              onClick={() => {}}
            />
            <StatCard
              title="Available Properties"
              color="mediumSeaGreen"
              value={stats.availableProperties}
              icon={<GiRoad size={iconSize} color="white" />}
              onClick={() => {}}
            />
            <StatCard
              title="Total Properties"
              color="orange"
              value={stats.totalProperties}
              icon={<RiNumbersLine size={iconSize} color="white" />}
              onClick={() => {}}
            />
          </SimpleGrid>
          {/* <img src="/images/logo.png" alt="" /> */}
        </>
      ) : (
        <Box display="flex" justifyContent="center" mt="15px">
          <Spinner size="lg" />
        </Box>
      )}
      {auth.user.role === roleTypes.admin ? (
        <>
          <Form inline>
            <Form.Group>
              <Form.Label>Date From</Form.Label>
              <Form.Control
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                style={{ marginLeft: 10 }}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: 20 }}>
              <Form.Label>Date To</Form.Label>
              <Form.Control
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                style={{ marginLeft: 10 }}
              />
            </Form.Group>
            <Button
              variant="primary"
              size="sm"
              onClick={() => getCompanyComparison()}
              style={{ marginLeft: 20 }}
            >
              <i className="fa fa-search"></i>
            </Button>
            {/* {this.state.loading ? (
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <></>
        )} */}
          </Form>
          {!loadingCompanyComparison ? (
            <>
              <SimpleGrid
                columns={{ base: 1, sm: 1 }}
                spacingX={{ base: "0px", sm: "20px" }}
                spacingY={{ base: "20px" }}
                mt="20px"
                mb="20px"
              >
                <CardContainer>
                  <Chart
                    options={options}
                    series={series}
                    type="line"
                    height="500px"
                  />
                </CardContainer>
              </SimpleGrid>
              {/* <img src="/images/logo.png" alt="" /> */}
            </>
          ) : (
            <Box display="flex" justifyContent="center" mt="15px">
              <Spinner size="lg" />
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
