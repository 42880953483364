import React, { Component } from "react";
import { Button } from "react-bootstrap";
import CreateEditReservationFee from "./CreateEditReservationFee";
import ConfirmDelete from "../menu/ConfirmDelete";
import Axios from "axios";
import { formatNumberWithCommas } from "../../utils";
import MaterialTable from "material-table";

class ReservationFeesList extends Component {
  state = {
    reservationFees: [],
    showCreateEdit: false,
    showDelete: false,
    itemId: "",
  };

  getReservationFees() {
    Axios.get("/api/reservation-fees")
      .then((res) => {
        const { data } = res;
        this.setState({
          reservationFees: data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleShow = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "create":
        this.setState({
          showCreateEdit: true,
          itemId: "",
        });
        break;
      case "edit":
        this.setState({
          showCreateEdit: true,
          itemId: value,
        });
        break;
      case "delete":
        this.setState({ showDelete: true, itemId: value });
        break;
      default:
        break;
    }
  };

  handleHide = (e) => {
    this.setState({
      showCreateEdit: false,
      showDelete: false,
    });
  };

  handleCreate = (data) => {
    const reservationFees = this.state.reservationFees.concat(data);
    this.setState({
      reservationFees,
    });
  };

  handleEdit = (id, data) => {
    let reservationFees = [...this.state.reservationFees];
    const index = reservationFees.findIndex((m) => m.id === id);
    reservationFees[index] = data;
    this.setState({
      reservationFees,
    });
  };

  handleDelete = (id) => {
    Axios.delete("/api/reservation-fees/" + id)
      .then((res) => {
        let reservationFees = this.state.reservationFees.filter(
          (m) => m.id !== id
        );
        this.setState({
          reservationFees,
        });
      })
      .catch((err) => {
        if (err.response) alert(err.response.data.message);
        else if (err.message) alert(err.message);
        else {
          console.log(err);
          alert("Error");
        }
      });
  };

  componentDidMount() {
    this.getReservationFees();
  }

  render() {
    return (
      <div className="container mt-3">
        <h2 className="text-center h2">Reservation Fees</h2>
        <Button name="create" onClick={this.handleShow} variant="primary">
          Add +
        </Button>
        <CreateEditReservationFee
          id={this.state.itemId}
          show={this.state.showCreateEdit}
          onHide={this.handleHide}
          onCreate={this.handleCreate}
          onEdit={this.handleEdit}
        />
        <ConfirmDelete
          id={this.state.itemId}
          show={this.state.showDelete}
          onHide={this.handleHide}
          onDelete={this.handleDelete}
        />
        <div className="mt-3" />
        <MaterialTable
          title=""
          data={this.state.reservationFees}
          columns={[
            {
              title: "Amount",
              render: (rowData) => formatNumberWithCommas(rowData.amount),
            },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "edit", value: rowData.id },
                });
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "delete", value: rowData.id },
                });
              },
            },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100],
            exportButton: true,
            exportAllData: true,
            actionsColumnIndex: -1,
          }}
        ></MaterialTable>
      </div>
    );
  }
}

export default ReservationFeesList;
