import React from "react";
import { Form } from "react-bootstrap";
import { formatNumberWithCommas } from "../../utils";
import {
  Text,
  NumberInput,
  NumberInputField as NumberField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

function NumberInputField({ field, label, value, onChange, errors, commas }) {
  const error = errors ? errors[field] : "";
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <NumberInput
        value={value ? (commas ? formatNumberWithCommas(value) : value) : 0}
        onChange={(valueString, valueNumber) => onChange(field, valueNumber)}
        step={1}
        defaultValue={0}
        borderColor={error ? "red" : "lightgray"}
      >
        <NumberField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {error ? (
        <Text color="red" fontSize="12px">
          {error}
        </Text>
      ) : (
        <></>
      )}
    </Form.Group>
  );
}

export default NumberInputField;
