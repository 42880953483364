import React, { Component } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";

class ChangePassword extends Component {
  state = {
    userId: "",
    // user: {
    name: "",
    email: "",
    // },
    newPassword: "",
    confirmNewPassword: "",
  };

  onClick = (e) => {
    const user = { ...this.state };
    if (user.newPassword !== user.confirmNewPassword) {
      alert("Password and Confirm Password do not match");
      return;
    }

    Axios.post("/api/users/change-password", user)
      .then((res) => {
        // this.props.onChangePassword(res.data);
        alert("Password was changed successfully");
        this.props.onHide();
      })
      .catch((err) => alert("Password was not changed")); //alert(err));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEntering = () => {
    const { id } = this.props;
    this.setState({
      userId: id,
    });
    Axios.get(`/api/users/${id}`)
      .then((res) => {
        const { email, name } = res.data;
        this.setState({
          email,
          name,
        });
        // this.setState({
        //   user: res.data,
        // });
      })
      .catch((err) => {});
  };

  onExited = () => {
    this.setState({
      userId: "",
      // user: {
      name: "",
      email: "",
      // },
      newPassword: "",
      confirmNewPassword: "",
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntering={this.onEntering}
        onExited={this.onExited}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control value={this.state.name} disabled />
                  {/* <Form.Control value={this.state.user.name} disabled /> */}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control value={this.state.email} disabled />
                  {/* <Form.Control value={this.state.user.email} disabled /> */}
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmNewPassword"
                    value={this.state.confirmNewPassword}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onClick}>{"Change Password"}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangePassword;
