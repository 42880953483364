import Axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    // Axios.defaults.headers.common["Authorization"] = token;
    Axios.defaults.headers.common["x-auth-token"] = token;
  } else {
    // Delete auth header
    // delete Axios.defaults.headers.common["Authorization"];
    delete Axios.defaults.headers.common["x-auth-token"];
  }
};

export default setAuthToken;
