import React from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmDelete({ id, show, onDelete, onHide }) {
  const onClick = (e) => {
    onDelete(id);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Are your sure you want to delete?</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button name="yes" onClick={onClick}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDelete;
