import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import TextInputField from "../menu/TextInputField";
import Axios from "axios";

function CreateEditCompany({ id, show, onHide, onCreate, onEdit }) {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const onClick = (e) => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    Axios.post("/api/companies", formData)
      .then((res) => {
        onCreate(res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const handleEdit = () => {
    Axios.put("/api/companies/" + id, formData)
      .then((res) => {
        onEdit(id, res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const onChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const onEntering = () => {
    if (id) {
      Axios.get(`/api/companies/${id}`)
        .then((res) => {
          const { name, code } = res.data;
          setFormData({
            name,
            code,
          });
        })
        .catch((err) => {});
    }
  };

  const onExited = () => {
    setFormData({
      name: "",
      code: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEntering={onEntering}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit" : "Create"} Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextInputField
            field="name"
            label="Name"
            value={formData.name}
            onChange={onChange}
            errors={formErrors}
          />
          <TextInputField
            field="code"
            label="Code"
            value={formData.code}
            onChange={onChange}
            errors={formErrors}
          />
          {/* <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                name="name"
                value={formData.name}
                onChange={onChange}
              />
            </Form.Group> */}
          {/* <Form.Group>
              <Form.Label>Code</Form.Label>
              <Form.Control
                name="code"
                value={formData.code}
                onChange={onChange}
              />
            </Form.Group> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{id ? "Edit" : "Create"}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateEditCompany;

// import React, { Component } from "react";
// import { Form, Modal, Button } from "react-bootstrap";
// import Axios from "axios";

// class CreateEditCompany extends Component {
//   state = {
//     name: "",
//     code: "",
//   };

//   onClick = (e) => {
//     const { id } = this.props;
//     if (id) {
//       this.props.onEdit(id, this.state);
//     } else {
//       this.props.onCreate(this.state);
//     }
//     this.props.onHide();
//   };

//   onChange = (e) => {
//     this.setState({ [e.target.name]: e.target.value });
//   };

//   onEntering = () => {
//     const { id } = this.props;
//     if (id) {
//       Axios.get(`/api/companies/${id}`)
//         .then((res) => {
//           const { name, code } = res.data;
//           this.setState({
//             name,
//             code,
//           });
//         })
//         .catch((err) => {});
//     }
//   };

//   onExited = () => {
//     this.setState({
//       name: "",
//       code: "",
//     });
//   };

//   render() {
//     const { id } = this.props;

//     return (
//       <Modal
//         show={this.props.show}
//         onHide={this.props.onHide}
//         onEntering={this.onEntering}
//         onExited={this.onExited}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{id ? "Edit" : "Create"} Company</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={(e) => e.preventDefault()}>
//             <Form.Group>
//               <Form.Label>Name</Form.Label>
//               <Form.Control
//                 name="name"
//                 value={this.state.name}
//                 onChange={this.onChange}
//               />
//             </Form.Group>
//             <Form.Group>
//               <Form.Label>Code</Form.Label>
//               <Form.Control
//                 name="code"
//                 value={this.state.code}
//                 onChange={this.onChange}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={this.onClick}>{id ? "Edit" : "Create"}</Button>
//         </Modal.Footer>
//       </Modal>
//     );
//   }
// }

// export default CreateEditCompany;
