import "./Dashboard.scss";
import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../common/PrivateRoute";

import { connect } from "react-redux";

// import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navigation from "./Navigation";

import { roleTypes } from "../../config";
import UsersList from "../user/UsersList";

import Home from "../home/Home";
import BuildingLocationsList from "../buildingLocation/BuildingLocationsList";
import PaymentMethodsList from "../paymentMethod/PaymentMethodsList";
import ProjectsList from "../project/ProjectsList";
import ProjectTypesList from "../projectType/ProjectTypesList";
import UnitFloorsList from "../unitFloor/UnitFloorsList";
import UnitTypesList from "../unitType/UnitTypesList";
import CompaniesList from "../company/CompaniesList";
import BrokersList from "../broker/BrokersList";
import BookingsList from "../bookings/BookingsList";
import ReservationFeesList from "../reservationFee/ReservationFeesList";
import ProjectBookings from "../project/ProjectBookings";
import SettingsList from "../setting/SettingsList";
import BookingReport from "../reports/BookingReport";

import { AiFillCaretRight } from "react-icons/ai";
import { RiDashboardLine, RiAdminLine } from "react-icons/ri";
import { BiBuildingHouse } from "react-icons/bi";
import { FaHandshake, FaMoneyBillWave } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { MdPayment } from "react-icons/md";
import { BsCash, BsBuilding } from "react-icons/bs";
import { HiOutlineDocumentReport } from "react-icons/hi";

function Dashboard({ auth, match }) {
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarItems, setSidebarItems] = useState([]);

  useEffect(() => {
    if (sidebarItems.length > 0) return;

    const { user } = auth;
    const items = [...sidebarItems];

    items.push({ name: "Dashboard", link: "", icon: <RiDashboardLine /> });

    if (user.role === roleTypes.admin) {
      items.push({
        name: "Properties",
        link: "/projects",
        icon: <BiBuildingHouse />,
      });

      items.push({
        name: "Building Locations",
        link: "/building-locations",
        icon: <GoLocation />,
      });

      items.push("divider");

      items.push({
        name: "Project Types",
        link: "/project-types",
        icon: <AiFillCaretRight />,
      });

      items.push({
        name: "Payment Methods",
        link: "/payment-methods",
        icon: <MdPayment />,
      });

      items.push({
        name: "Reservation Fees",
        link: "/reservation-fees",
        icon: <BsCash />,
      });

      items.push("divider");

      items.push({
        name: "Companies",
        link: "/companies",
        icon: <BsBuilding />,
      });

      items.push({
        name: "Brokers",
        link: "/brokers",
        icon: <FaHandshake />,
      });

      items.push({
        name: "Bookings",
        link: "/bookings",
        icon: <FaMoneyBillWave />,
      });

      items.push({
        name: "Admins",
        link: "/users",
        icon: <RiAdminLine />,
      });

      items.push({
        name: "Settings",
        link: "/settings",
        icon: <FiSettings />,
      });

      items.push("divider");

      items.push({
        name: "Bookings Report",
        link: "/reports/booking",
        icon: <HiOutlineDocumentReport />,
      });
      setSidebarItems(items);
    } else if (user.role === roleTypes.employee) {
      items.push({
        name: "Properties",
        link: "/projects",
        icon: <BiBuildingHouse />,
      });
      items.push({
        name: "Bookings",
        link: "/bookings",
        icon: <FaMoneyBillWave />,
      });
      setSidebarItems(items);
    }
  }, [auth, sidebarItems]);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const { url } = match;
  return (
    <React.Fragment>
      <div className={`app ${toggled ? "toggled" : ""}`}>
        <Sidebar
          items={sidebarItems}
          url={url}
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
        />
        <main className="content-area">
          <div className="navbar">
            <Navigation
              toggled={toggled}
              collapsed={collapsed}
              handleToggleSidebar={handleToggleSidebar}
              handleCollapsedChange={handleCollapsedChange}
            />
          </div>
          <div>
            <Switch>
              <PrivateRoute exact path={`${url}/`} component={Home} />
              <PrivateRoute
                path={`${url}/building-locations`}
                component={BuildingLocationsList}
                roles={[roleTypes.admin]}
              />

              <PrivateRoute
                path={`${url}/unit-floors/:id`}
                component={UnitFloorsList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/projects`}
                component={ProjectsList}
                roles={[roleTypes.admin, roleTypes.employee]}
              />
              <PrivateRoute
                path={`${url}/project-bookings/:id`}
                component={ProjectBookings}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/companies`}
                component={CompaniesList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/brokers`}
                component={BrokersList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/bookings`}
                component={BookingsList}
                roles={[roleTypes.admin, roleTypes.employee]}
              />
              <PrivateRoute
                path={`${url}/users`}
                component={UsersList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/unit-types/:id`}
                component={UnitTypesList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/project-types`}
                component={ProjectTypesList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/payment-methods`}
                component={PaymentMethodsList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/reservation-fees`}
                component={ReservationFeesList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/settings`}
                component={SettingsList}
                roles={[roleTypes.admin]}
              />
              <PrivateRoute
                path={`${url}/reports/booking`}
                component={BookingReport}
                roles={[roleTypes.admin]}
              />
            </Switch>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Dashboard);
