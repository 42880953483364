import React from "react";
import { Box } from "@chakra-ui/react";

function CardContainer(props) {
  return (
    <Box bg="white" shadow="md" borderRadius="md" padding="10px">
      {props.children}
    </Box>
  );
}

export default CardContainer;
