import React from "react";
import {
  Box,
  Flex,
  Text,
  // Stat,
  // StatLabel,
  // StatNumber,
  // StatHelpText,
  //   StatArrow,
  //   StatGroup,
} from "@chakra-ui/react";

function StatCard({ title, value, color, icon, onClick }) {
  return (
    <Box
      height="100px"
      bg="white"
      onClick={() => onClick()}
      shadow="md"
      borderRadius="md"
      display="flex"
    >
      <Flex direction="row" flex={1}>
        <Flex
          flex={2}
          alignItems="center"
          justifyContent="center"
          bg={color}
          padding="5px"
        >
          {icon}
        </Flex>
        <Flex
          flex={4}
          direction="column"
          justifyContent="center"
          padding="5px"
          ml="5px"
          mr="5px"
        >
          <Text fontSize="1.1em">{title}</Text>
          <Text fontSize="1.3em" mt="10px">
            {value}
          </Text>
        </Flex>
      </Flex>
      {/* <Stat>
        <StatLabel display="flex" alignItems="center" fontSize="18px">
          {icon}
          <Box ml="10px">{title}</Box>
        </StatLabel>
        <StatNumber>{value}</StatNumber>
        <StatHelpText>Feb 12 - Feb 28</StatHelpText>
      </Stat> */}
    </Box>
  );
}

export default StatCard;
