import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// import { roleTypes } from "../../config";

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const { isAuthenticated, user } = auth;
  return (
    <Route
      {...rest}
      render={(props) => {
        props = { ...props, ...rest };
        if (isAuthenticated === true) {
          const allowedRoles = rest.roles;
          const userRole = user.role;
          if (allowedRoles) {
            const result = allowedRoles.filter((m) => m === userRole);
            if (result && result.length > 0) {
              return <Component {...props} />;
            } else {
              return <Redirect to="/dashboard" />;
            }
          } else {
            return <Component {...props} />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
