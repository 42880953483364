import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Register from "./Register";
import ConfirmDelete from "../menu/ConfirmDelete";
import Axios from "axios";
import ChangePassword from "./ChangePassword";
import EditUser from "./EditUser";
import { connect } from "react-redux";

import MaterialTable from "material-table";
import { roleTypes } from "../../config";

class UsersList extends Component {
  state = {
    users: [],
    showRegister: false,
    showEdit: false,
    showChangePassword: false,
    showDelete: false,
    itemId: 0,
  };

  getUsers() {
    Axios.get(`/api/users?role=${roleTypes.admin}`)
      .then((res) => {
        const { data } = res;
        const users = data;
        // const users = data.filter(
        //   (m) => m.email !== this.props.auth.user.email
        // );
        this.setState({
          users,
        });
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleShow = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "register":
        this.setState({
          showRegister: true,
          itemId: 0,
        });
        break;
      case "changePassword":
        this.setState({
          showChangePassword: true,
          itemId: value,
        });
        break;
      case "edit":
        this.setState({
          showEdit: true,
          itemId: value,
        });
        break;
      case "delete":
        this.setState({ showDelete: true, itemId: value });
        break;
      default:
        break;
    }
  };

  handleHide = (e) => {
    this.setState({
      showRegister: false,
      showEdit: false,
      showChangePassword: false,
      showDelete: false,
    });
  };

  handleChangePassword = (data) => {
    if (data) {
      alert("Password was changed successfully");
    } else {
      alert("Password was not changed");
    }
  };

  handleCreate = (data) => {
    const users = this.state.users.concat(data);
    this.setState({
      users,
    });
  };

  handleEdit = (id, data) => {
    // let users = this.state.users;
    // const index = users.findIndex((m) => m.id === id);
    // users[index] = data;
    // this.setState({
    //   users,
    // });

    this.getUsers();

    Axios.put("/api/users/" + id, data)
      .then((res) => {
        let users = this.state.users;
        const index = users.findIndex((m) => m.id === id);
        // users[index] = res.data;
        users[index] = data;
        this.setState({
          users,
        });
      })
      .catch((err) => alert(err));
  };

  handleDelete = (id) => {
    Axios.delete("/api/users/" + id)
      .then((res) => {
        let users = this.state.users.filter((m) => m.id !== id);
        this.setState({
          users,
        });
      })
      .catch((err) => alert(err));
  };

  componentDidMount() {
    this.getUsers();
  }

  render() {
    return (
      <div className="container mt-3">
        <h2 className="text-center h2">Users</h2>
        <Button name="register" onClick={this.handleShow} variant="primary">
          Register +
        </Button>
        <Register
          show={this.state.showRegister}
          onHide={this.handleHide}
          onCreate={this.handleCreate}
        />
        <ChangePassword
          id={this.state.itemId}
          show={this.state.showChangePassword}
          onHide={this.handleHide}
        />
        <EditUser
          id={this.state.itemId}
          show={this.state.showEdit}
          onHide={this.handleHide}
          onEdit={this.handleEdit}
        />
        <ConfirmDelete
          id={this.state.itemId}
          show={this.state.showDelete}
          onHide={this.handleHide}
          onDelete={this.handleDelete}
        />
        <div className="mt-3" />
        <MaterialTable
          title=""
          data={this.state.users}
          columns={[
            { title: "Name", field: "name" },
            { title: "Email", field: "email" },
            { title: "Role", field: "role" },
            {
              title: "Active",
              field: "enabled",
              render: (rowData) => (rowData.enabled ? "Yes" : "No"),
            },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "edit", value: rowData.id },
                });
              },
            },
            {
              icon: "lock",
              tooltip: "Change Password",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "changePassword", value: rowData.id },
                });
              },
            },
            {
              icon: "delete",
              tooltip: "Delete",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "delete", value: rowData.id },
                });
              },
            },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100],
            exportButton: true,
            exportAllData: true,
            actionsColumnIndex: -1,
          }}
        ></MaterialTable>
        {/* <ListTable headers={headers}>
          {this.state.users.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.role}</td>
                <td>{item.enabled ? "Yes" : "No"}</td>
                <td>
                  <Button
                    name="changePassword"
                    value={item.id}
                    className="ml-3"
                    variant="primary"
                    onClick={this.handleShow}
                  >
                    Change Password
                  </Button>
                  <Button
                    name="edit"
                    value={item.id}
                    onClick={this.handleShow}
                    className="ml-3"
                    variant="info"
                  >
                    <i className="fa fa-pencil"></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </ListTable> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UsersList);
