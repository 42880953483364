import React, { useState } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { formatNumberWithCommas, formatDecimal } from "../../utils";
import TextInputField from "../menu/TextInputField";
import NumberInputField from "../menu/NumberInputField";
import DropdownField from "../menu/DropdownField";

function CreateEditProject({ id, show, onHide, onCreate, onEdit }) {
  const [formData, setFormData] = useState({
    buildingLocationId: 0,
    projectTypeId: 0,
    unitFloorId: 0,
    unitTypeId: 0,
    unitNumber: "",
    unitSize: 0,
    unitPrice: 0,
    pricePerUnit: 0,
    reservationFee: 0,
    installments: 0,

    // pricePerUnit: 0, //temp field
  });

  const [formErrors, setFormErrors] = useState({});

  const [projectTypes, setProjectTypes] = useState([]);
  const [buildingLocations, setBuildingLocations] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [unitFloors, setUnitFloors] = useState([]);
  const [reservationFees, setReservationFees] = useState([]);

  const onClick = (e) => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const excludeFieldsFromState = (state, fields) => {
    let data = { ...state };
    for (let i = 0; i < fields.length; i++) {
      delete data[fields[i]];
    }
    return data;
  };

  const handleCreate = () => {
    //   delete project.pricePerUnit;
    const data = excludeFieldsFromState(formData, [
      "buildingLocationId",
      // "pricePerUnit",
    ]);
    Axios.post("/api/projects", data)
      .then((res) => {
        onCreate(res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const handleEdit = () => {
    //   delete project.pricePerUnit;
    const data = excludeFieldsFromState(formData, [
      "id",
      "buildingLocationId",
      // "pricePerUnit",
      "ProjectTypeId",
      "UnitTypeId",
      "UnitFloorId",
    ]);
    Axios.put("/api/projects/" + id, data)
      .then((res) => {
        onEdit(id, res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const onChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const onSelect = (field, valueField, item, callback) => {
    setFormData({ ...formData, [field]: item[valueField] });
    setFormErrors({ ...formErrors, [field]: "" });
    if (callback) callback(item);
  };

  const onEntering = () => {
    Axios.get(`/api/project-types`)
      .then((res) => {
        setProjectTypes(res.data);
      })
      .catch((err) => {});

    Axios.get(`/api/building-locations`)
      .then((res) => {
        setBuildingLocations(res.data);
      })
      .catch((err) => {});

    Axios.get(`/api/reservation-fees`)
      .then((res) => {
        setReservationFees(res.data);
      })
      .catch((err) => {});

    if (id) {
      Axios.get(`/api/projects/${id}`)
        .then((res) => {
          const { unitPrice, unitSize, unitFloorId } = res.data;
          Axios.get(`/api/unit-floors/${unitFloorId}`)
            .then((res2) => {
              const { buildingLocationId } = res2.data;
              onSelectBuildingLocation({ id: buildingLocationId });
              setFormData({
                ...res.data,
                buildingLocationId,
                // pricePerUnit: formatDecimal(unitPrice / unitSize),
              });
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  };

  const onExited = () => {
    setFormData({
      buildingLocationId: 0,
      projectTypeId: 0,
      unitFloorId: 0,
      unitTypeId: 0,
      unitNumber: "",
      unitSize: 0,
      unitPrice: 0,
      pricePerUnit: 0,
      reservationFee: 0,
      installments: 0,

      // pricePerUnit: 0, //temp field
    });
  };

  const onSelectProjectType = (option) => {
    let project = { ...formData };
    project.projectTypeId = option.id;
    setFormData(project);
  };

  const onSelectBuildingLocation = (option) => {
    setFormData({ ...formData, buildingLocationId: option.id });

    Axios.get(`/api/unit-floors/by-location/${option.id}`)
      .then((res) => {
        setUnitFloors(res.data);
      })
      .catch((error) => {
        alert(error);
      });

    Axios.get(`/api/unit-types/by-location/${option.id}`)
      .then((res) => {
        setUnitTypes(res.data);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const onSelectUnitType = (option) => {
    setFormData({
      ...formData,
      unitTypeId: option.id,
    });
  };

  const onSelectUnitFloor = (option) => {
    setFormData({
      ...formData,
      unitFloorId: option.id,
    });
  };

  const onSelectReservationFee = (option) => {
    setFormData({
      ...formData,
      reservationFee: option.amount,
    });
  };

  const projectType = projectTypes.find((m) => m.id === formData.projectTypeId);

  const buildingLocation = buildingLocations.find(
    (m) => m.id === formData.buildingLocationId
  );

  const unitType = unitTypes.find((m) => m.id === formData.unitTypeId);

  const unitFloor = unitFloors.find((m) => m.id === formData.unitFloorId);

  const reservationFee = reservationFees.find(
    (m) => m.amount === formData.reservationFee
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEntering={onEntering}
      onExited={onExited}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit" : "Create"} Property</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col sm={6}>
              <DropdownField
                label="Project Type"
                value={projectType ? projectType : ""}
                options={projectTypes}
                onChange={onSelect}
                callback={onSelectProjectType}
                field="projectTypeId"
                labelField="name"
                valueField="id"
                errors={formErrors}
              />
            </Col>
            <Col sm={6}>
              <DropdownField
                label="Building Location"
                value={buildingLocation ? buildingLocation : ""}
                options={buildingLocations}
                onChange={onSelect}
                callback={onSelectBuildingLocation}
                labelField="name"
                valueField="id"
                disabled={buildingLocations.length < 1}
                errors={formErrors}
              />
            </Col>
            <Col sm={6}>
              <DropdownField
                label="Unit Type"
                value={unitType ? unitType : ""}
                options={unitTypes}
                onChange={onSelect}
                callback={onSelectUnitType}
                field="unitTypeId"
                labelField="name"
                valueField="id"
                disabled={unitTypes.length < 1}
                errors={formErrors}
              />
            </Col>
            <Col sm={6}>
              <DropdownField
                label="Unit Floor"
                value={unitFloor ? unitFloor : ""}
                options={unitFloors}
                onChange={onSelect}
                callback={onSelectUnitFloor}
                field="unitFloorId"
                labelField="name"
                valueField="id"
                disabled={unitTypes.length < 1}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <TextInputField
                field="unitNumber"
                label="Unit Number"
                value={formData.unitNumber}
                onChange={onChange}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <NumberInputField
                field="pricePerUnit"
                label="Price (per sqm)"
                value={formatNumberWithCommas(formData.pricePerUnit)}
                onChange={onChange}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <NumberInputField
                field="unitSize"
                label="Unit Size"
                value={formData.unitSize}
                onChange={onChange}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <NumberInputField
                field="unitPrice"
                label="Unit Price"
                value={formatNumberWithCommas(formData.unitPrice)}
                onChange={onChange}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <DropdownField
                label="Reservation Fee"
                value={reservationFee ? reservationFee : ""}
                options={reservationFees}
                onChange={onSelect}
                callback={onSelectReservationFee}
                field="reservationFee"
                labelField="amount"
                valueField="id"
                disabled={reservationFees.length < 1}
                errors={formErrors}
              />
            </Col>
            <Col sm={4}>
              <NumberInputField
                field="installments"
                label="Total Installments"
                value={formData.installments}
                onChange={onChange}
                errors={formErrors}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{id ? "Edit" : "Create"}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateEditProject;
