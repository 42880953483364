import React from "react";
import MaterialTable from "material-table";
import Axios from "axios";
import { getFormattedDateWithTime } from "../../utils";
import { Form, Button, Spinner } from "react-bootstrap";

class BookingReport extends React.Component {
  state = {
    dateFrom: "",
    dateTo: "",
    report: [],
    loading: false,
  };

  validate() {
    if (this.state.dateFrom === "" || this.state.dateTo === "") {
      alert("Please Enter Dates");
      return false;
    }
    return true;
  }

  getData() {
    if (this.validate()) {
      this.setState({ loading: true, report: [] }, () => {
        let { dateFrom, dateTo } = this.state;
        dateFrom += " 00:00:00";
        dateTo += " 23:59:59";
        Axios.post("/api/bookings/report/get", { dateFrom, dateTo })
          .then((res) => {
            let { data } = res;
            for (let i = 0; i < data.length; i++) {
              data[i].date = getFormattedDateWithTime(data[i].date);
            }
            this.setState({
              report: data,
              loading: false,
            });
          })
          .catch((error) => {
            alert(error);
            this.setState({ loading: true });
          });
      });
    }
  }

  onChange = (e) => {
    let state = { ...this.state };
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  componentDidMount() {
    // this.getData();
    this.setState({
      dateFrom: new Date().toLocaleDateString("en-CA"),
      dateTo: new Date().toLocaleDateString("en-CA"),
    });
  }

  search = () => {
    this.getData();
  };

  render() {
    return (
      <div className="container-fluid mt-3">
        <h2 className="text-center h2">Bookings Report</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <Form inline>
            <Form.Group>
              <Form.Label>Date From</Form.Label>
              <Form.Control
                name="dateFrom"
                type="date"
                value={this.state.dateFrom}
                onChange={this.onChange}
                style={{ marginLeft: 10 }}
              />
            </Form.Group>
            <Form.Group style={{ marginLeft: 20 }}>
              <Form.Label>Date To</Form.Label>
              <Form.Control
                name="dateTo"
                type="date"
                value={this.state.dateTo}
                onChange={this.onChange}
                style={{ marginLeft: 10 }}
              />
            </Form.Group>
            <Button
              variant="primary"
              size="sm"
              onClick={this.search}
              style={{ marginLeft: 20 }}
            >
              <i className="fa fa-search"></i>
            </Button>
            {this.state.loading ? (
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <></>
            )}
          </Form>
        </div>
        <div className="mt-3" />
        <MaterialTable
          title=""
          data={this.state.report}
          columns={[
            { title: "Buyer Name", field: "buyerName" },
            { title: "Buyer Mobile", field: "buyerMobile" },
            { title: "Buyer Email", field: "buyerEmail" },
            { title: "Buyer QID", field: "buyerQID" },
            { title: "Status", field: "status" },
            { title: "Filled By", field: "filledBy" },
            { title: "Company", field: "company" },
            { title: "Installments", field: "installments" },
            { title: "Reservation Fee", field: "reservationFee" },
            { title: "Reference Number", field: "referenceNumber" },
            { title: "Date", field: "date" },
            { title: "Building Location", field: "buildingLocation" },
            { title: "Unit Floor", field: "unitFloor" },
            { title: "Unit Type", field: "unitType" },
            { title: "Project Type", field: "projectType" },
            { title: "Unit Number", field: "unitNumber" },
            { title: "Unit Size (SQM)", field: "unitSize" },
            { title: "Unit Price (QAR)", field: "unitPrice" },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100],
            exportButton: true,
            exportAllData: true,
          }}
        ></MaterialTable>
      </div>
    );
  }
}

export default BookingReport;
