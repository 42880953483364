import Axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER } from "./types";
// import config from "../config";

export const registerUser = (userData, history) => (dispatch) => {
  Axios.post("/api/users/register", userData)
    .then((res) => history.push("/login"))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const loginUser = (userData) => (dispatch) => {
  Axios.post("/api/users/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      const decoded = jwt_decode(token);
      setAuthToken(token);
      dispatch(setCurrentUser(decoded));
      // if (decoded.role === config.roleTypes.admin) {
      //   setAuthToken(token);
      //   dispatch(setCurrentUser(decoded));
      // } else {
      //   alert("Only Admins are allowed access");
      // }
    })
    .catch((err) => {
      console.log(err);
      alert("Invalid Username or Password");
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  // localStorage.removeItem("jwtToken");
  // setAuthToken(false);
  dispatch(setCurrentUser({}));
};
