import React, { Component } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";

class EditUser extends Component {
  state = {
    userId: "",
    user: {
      name: "",
      email: "",
      enabled: false,
    },
  };

  onClick = (e) => {
    const user = { ...this.state.user };

    Axios.put("/api/users/" + this.state.userId, user)
      .then((res) => {
        this.props.onEdit(this.state.userId, res.data);
        this.props.onHide();
      })
      .catch((err) => console.log(err)); //alert(err));
  };

  onChange = (e) => {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  onCheckChange = (e) => {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.checked;
    this.setState({
      user,
    });
  };

  onEntering = () => {
    const { id } = this.props;
    Axios.get(`/api/users/${id}`)
      .then((res) => {
        this.setState({
          userId: id,
          user: res.data,
        });
      })
      .catch((err) => {});
  };

  onExited = () => {
    this.setState({
      userId: "",
      user: {
        name: "",
        email: "",
        enabled: false,
      },
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntering={this.onEntering}
        onExited={this.onExited}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Enabled</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="enabled"
                    checked={this.state.user.enabled}
                    onChange={this.onCheckChange}
                  ></Form.Check>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control value={this.state.user.name} disabled />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control value={this.state.user.email} disabled />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onClick}>{"Edit"}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditUser;
