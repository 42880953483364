import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import { HStack, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { BsPower } from "react-icons/bs";
import { RiMenu2Line } from "react-icons/ri";
import { FiChevronDown } from "react-icons/fi";

function Navigation({
  items,
  url,
  auth,
  toggled,
  collapsed,
  handleToggleSidebar,
  handleCollapsedChange,
  logoutUser,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  const logout = (e) => {
    e.preventDefault();
    logoutUser();
  };

  const mobile = width < 768;
  if (mobile) {
    handleCollapsedChange(false);
  }

  return (
    <React.Fragment>
      <div className="mr-auto">
        <RiMenu2Line
          className="sidebar-toggle"
          onClick={() =>
            mobile
              ? handleToggleSidebar(true)
              : handleCollapsedChange(!collapsed)
          }
          // onClick={() =>
          //   !toggled
          //     ? handleToggleSidebar(true)
          //     : handleCollapsedChange(!collapsed)
          // }
          // onClick={() => handleCollapsedChange(!collapsed)}
        />
      </div>
      <div className="ml-auto inline">
        <Menu>
          <MenuButton>
            <HStack>
              <p>{auth.user.name}</p>
              <FiChevronDown />
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={logout}>
              <HStack>
                <BsPower />
                <p>Logout</p>
              </HStack>
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navigation);
