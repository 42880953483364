import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Tooltip } from "@chakra-ui/react";

function Sidebar({
  items,
  url,
  // auth,
  collapsed,
  toggled,
  handleToggleSidebar,
}) {
  return (
    <ul className="sidebar-items">
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          <SidebarHeader>
            <div className="sidebar-header-container">
              {collapsed ? (
                <img
                  className="sidebar-header-image-small"
                  src="/images/logo-horizontal-small.png"
                  alt=""
                />
              ) : (
                <img
                  className="sidebar-header-image"
                  src="/images/logo-horizontal.png"
                  alt=""
                />
              )}
            </div>
          </SidebarHeader>
        </SidebarHeader>
        <SidebarContent className="sidebar-content">
          <Menu>
            {items.map((value, index) => {
              if (value === "divider")
                return (
                  <li key={index}>
                    <hr
                      style={{
                        borderTop: "1px solid #666",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  </li>
                );
              else
                return (
                  <MenuItem icon={value.icon} key={index}>
                    <Tooltip
                      label={value.name}
                      placement="right"
                      isDisabled={!collapsed}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: 14,
                          }}
                        >
                          {value.name}
                        </p>
                        <Link to={`${url}${value.link}`}></Link>
                      </div>
                    </Tooltip>
                  </MenuItem>
                );
            })}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </ul>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Sidebar);
