import React, { Component } from "react";
import BuyerDetails from "./BuyerDetails";
import Axios from "axios";

import { getFormattedDateWithTime } from "../../utils";

import MaterialTable from "material-table";

class ProjectBookingsList extends Component {
  state = {
    projectBookings: [],
    showDetails: false,
    itemId: 0,
  };

  getProjectBookings() {
    Axios.get(`/api/bookings?fields=1&project=${this.props.match.params.id}`)
      .then((res) => {
        const { data } = res;
        this.setState({
          projectBookings: data,
        });
      })
      .catch((error) => {
        alert(error);
      });
  }

  handleShow = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "details":
        this.setState({
          showDetails: true,
          itemId: value,
        });
        break;
      default:
        break;
    }
  };

  handleHide = (e) => {
    this.setState({
      showDetails: false,
    });
  };

  componentDidMount() {
    this.getProjectBookings();
  }

  render() {
    const first = this.state.projectBookings
      ? this.state.projectBookings[0]
      : null;
    return (
      <div className="container mt-3">
        <h2 className="text-center h2">Property Booking Details</h2>
        <BuyerDetails
          id={this.state.itemId}
          show={this.state.showDetails}
          onHide={this.handleHide}
        />
        <div className="mt-3" />
        <MaterialTable
          title=""
          data={this.state.projectBookings}
          columns={[
            {
              title: "Property",
              render: (rowData) => rowData.Project.unitNumber,
            },
            {
              title: "Date",
              render: (rowData) => getFormattedDateWithTime(rowData.dateTime),
            },
            {
              title: "Status",
              render: (rowData) =>
                first && first.id === rowData.id ? (
                  <p
                    style={{
                      color: "white",
                      background: "MediumSeaGreen",
                      textAlign: "center",
                    }}
                  >
                    Buyer
                  </p>
                ) : (
                  <p
                    style={{
                      color: "white",
                      background: "orange",
                      textAlign: "center",
                    }}
                  >
                    Waiting
                  </p>
                ),
            },
            {
              title: "Serial",
              field: "serialNumber",
            },
            { title: "Buyer", field: "buyerName" },
            {
              title: "Filled by",
              render: (rowData) => rowData.User.name,
              customFilterAndSearch: (term, rowData) =>
                rowData.User.name.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Company",
              render: (rowData) => rowData.User.Company.name,
              customFilterAndSearch: (term, rowData) =>
                rowData.User.Company.name
                  .toLowerCase()
                  .indexOf(term.toLowerCase()) > -1,
            },
          ]}
          actions={[
            {
              icon: "info",
              tooltip: "Buyer Details",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "details", value: rowData },
                });
              },
            },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100],
            exportButton: true,
            exportAllData: true,
            actionsColumnIndex: -1,
            filtering: true,
          }}
        ></MaterialTable>
      </div>
    );
  }
}

export default ProjectBookingsList;
