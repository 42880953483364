import React, { Component } from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Axios from "axios";
import { roleTypes } from "../../config";

class RegisterBroker extends Component {
  state = {
    user: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: roleTypes.employee,
      companyId: 0,
    },
    companies: [],
  };

  onClick = (e) => {
    const user = { ...this.state.user };
    if (user.password !== user.confirmPassword) {
      alert("Password and Confirm Password do not match");
      return;
    }
    Axios.post("/api/users/register", user)
      .then((res) => {
        this.props.onCreate(res.data);
        this.props.onHide();
      })
      .catch((err) => alert(err));
  };

  onChange = (e) => {
    let user = { ...this.state.user };
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  onEntering = () => {
    Axios.get("/api/companies")
      .then((res) => {
        this.setState({
          companies: res.data,
        });
      })
      .catch((err) => {});
  };

  onExited = () => {
    this.setState({
      user: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        role: roleTypes.employee,
        companyId: 0,
      },
      companies: [],
    });
  };

  onSelectCompany = (option) => {
    let user = { ...this.state.user };
    user.companyId = option.id;

    this.setState({
      user,
    });
  };

  render() {
    const { id } = this.props;

    const company = this.state.companies.find(
      (m) => m.id === this.state.user.companyId
    );

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEntering={this.onEntering}
        onExited={this.onExited}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Register Broker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Company</Form.Label>
                  <Select
                    value={company ? company : ""}
                    options={this.state.companies}
                    onChange={this.onSelectCompany}
                    getOptionLabel={(n) => n.name}
                    getOptionValue={(n) => n.id}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={this.state.user.name}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={this.state.user.email}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={this.state.user.password}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={this.state.user.confirmPassword}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onClick}>{id ? "Edit" : "Create"}</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RegisterBroker;
