import React, { Component } from "react";
import "./Login.css";

import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    } else {
      alert("Invalid username or password");
    }
  }
  onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  };
  render() {
    return (
      <div className="container">
        <div className="login-container">
          <form className="login-form" onSubmit={this.onSubmit}>
            <img className="login-img-logo" src={"/images/logo.png"} alt="" />
            <div className="form-group row">
              <input
                name="email"
                className="form-control"
                type="email"
                placeholder="Email"
                onChange={this.onChange}
              />
            </div>
            <div className="form-group row">
              <input
                name="password"
                className="form-control"
                type="password"
                placeholder="Password"
                onChange={this.onChange}
              />
            </div>
            <div className="form-group row">
              <button className="btn btn-dark btn-block" type="submit">
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
