// export const monthNamesFull = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

export const monthNames = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function formatDecimal(number, decimalPositions) {
  return Number.isInteger(number) ? number : number.toFixed(decimalPositions);
}

export function getNormalFormattedDate(date) {
  if (!date) return "";
  const d = new Date(date);
  let formattedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  return formattedDate;
}

export function getFormattedDate(date) {
  if (!date) return "";
  const d = new Date(date);
  let formattedDate = `${d.getDate()} ${
    monthNames[d.getMonth()]
  } ${d.getFullYear()}`;
  return formattedDate;
}

export function getFormattedTime(date) {
  if (!date) return "";
  const d = new Date(date);
  let formattedTime = d.toLocaleString("en-US", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  return formattedTime;
}

export function getFormattedDateWithTime(date) {
  if (!date) return "";
  const d = new Date(date);
  let formattedDate = getFormattedDate(d);
  // console.log(formattedDate);
  formattedDate += " " + getFormattedTime(d);
  // console.log(formattedDate);

  return formattedDate;
}

export function userInRole(user, role) {
  try {
    return user.role === role;
  } catch (error) {
    return false;
  }
}

export function formatNumberWithCommas(number) {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } catch (err) {
    return "";
  }
}

// export default {
//   getFormattedDate,
//   getFormattedTime,
//   getFormattedDateWithTime,
//   userInRole,
//   formatNumberWithCommas,
// };
