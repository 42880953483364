import React, { Component } from "react";
import BuyerDetails from "../project/BuyerDetails";
import Axios from "axios";
import { getFormattedDateWithTime } from "../../utils";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { roleTypes } from "../../config";

class BookingsList extends Component {
  state = {
    bookings: [],
    itemId: "",
    showBuyerDetails: false,
    loading: false,
  };

  getBookings() {
    const { id, role } = this.props.auth.user;
    this.setState({ loading: true }, () => {
      Axios.get(
        `/api/bookings?fields=2${
          role === roleTypes.employee ? `&user=${id}` : ""
        }`
      )
        .then((res) => {
          const { data } = res;
          this.setState({
            bookings: data,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          alert(error);
        });
    });
  }

  handleShow = (e) => {
    const { value } = e.target;
    console.log(value);
    switch (e.target.name) {
      case "buyerDetails":
        this.setState({ showBuyerDetails: true, itemId: value });
        break;
      default:
        break;
    }
  };

  handleHide = (e) => {
    this.setState({
      showBuyerDetails: false,
    });
  };

  componentDidMount() {
    this.getBookings();
  }

  render() {
    return (
      <div className="container-fluid mt-3">
        <h2 className="text-center h2">Bookings</h2>
        <BuyerDetails
          id={this.state.itemId}
          show={this.state.showBuyerDetails}
          onHide={this.handleHide}
        />
        <div className="mt-3" />
        <MaterialTable
          title=""
          data={this.state.bookings}
          isLoading={this.state.loading}
          columns={[
            {
              title: "Date/Time",
              render: (rowData) => getFormattedDateWithTime(rowData.dateTime),
              customFilterAndSearch: (term, rowData) =>
                rowData.dateTime.toLowerCase().indexOf(term.toLowerCase()) > -1,
            },
            {
              title: "Serial",
              render: (rowData) => rowData.serialNumber,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Project Type",
              render: (rowData) => rowData.Project.ProjectType.name,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Unit Type",
              render: (rowData) => rowData.Project.UnitType.name,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Unit Floor",
              render: (rowData) => rowData.Project.UnitFloor.name,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Unit Number",
              render: (rowData) => rowData.Project.unitNumber,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
            {
              title: "Booked By",
              render: (rowData) =>
                `${rowData.User.name} (${rowData.User.Company.name})`,
              customFilterAndSearch: (term, rowData) =>
                rowData.serialNumber.toLowerCase().indexOf(term.toLowerCase()) >
                -1,
            },
          ]}
          actions={[
            {
              icon: "info",
              tooltip: "Buyer Details",
              onClick: (event, rowData) => {
                this.handleShow({
                  target: { name: "buyerDetails", value: rowData },
                });
              },
            },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50, 100],
            exportButton: true,
            exportAllData: true,
            actionsColumnIndex: -1,
            filtering: true,
          }}
        ></MaterialTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(BookingsList);
