import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import TextInputField from "../menu/TextInputField";
import Axios from "axios";

function CreateEditPaymentMethod({ id, show, onHide, onCreate, onEdit }) {
  const [formData, setFormData] = useState({
    name: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const onClick = (e) => {
    if (id) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  const handleCreate = () => {
    Axios.post("/api/payment-methods", formData)
      .then((res) => {
        onCreate(res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const handleEdit = () => {
    Axios.put("/api/payment-methods/" + id, formData)
      .then((res) => {
        onEdit(id, res.data);
        onHide();
      })
      .catch((err) => {
        if (err.response) {
          const errors = err.response.data;
          if (errors.message) alert(errors.message);
          else setFormErrors(errors);
        } else console.log(err);
      });
  };

  const onChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
    setFormErrors({ ...formErrors, [field]: "" });
  };

  const onEntering = () => {
    if (id) {
      Axios.get(`/api/payment-methods/${id}`)
        .then((res) => {
          const { name } = res.data;
          setFormData({
            ...formData,
            name,
          });
        })
        .catch((err) => {});
    }
  };

  const onExited = () => {
    setFormData({
      name: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEntering={onEntering}
      onExited={onExited}
    >
      <Modal.Header closeButton>
        <Modal.Title>{id ? "Edit" : "Create"} Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => e.preventDefault()}>
          <TextInputField
            field="name"
            label="Name"
            value={formData.name}
            onChange={onChange}
            errors={formErrors}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClick}>{id ? "Edit" : "Create"}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateEditPaymentMethod;
