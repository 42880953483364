import React, { Component } from "react";
import { Button } from "react-bootstrap";
import CreateEditProject from "./CreateEditProject";
import ConfirmDelete from "../menu/ConfirmDelete";
import Axios from "axios";
import { formatNumberWithCommas } from "../../utils";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { roleTypes } from "../../config";

class ProjectsList extends Component {
  state = {
    projects: [],
    projectsSorted: [],
    showCreateEdit: false,
    showDelete: false,
    itemId: "",

    buyer: {},

    availableProjects: true,
    soldProjects: false,

    loading: false,
  };

  getProjects() {
    this.setState({ loading: true, projects: [] }, () => {
      Axios.get("/api/projects/export")
        .then((res) => {
          const { data } = res;
          this.setState({
            projects: data,
            projectsSorted: data.filter((m) => m.bookings < 1),
            availableProjects: true,
            soldProjects: false,
            loading: false,
          });
        })
        .catch((error) => {
          alert(error);
          this.setState({ loading: false });
        });
    });
  }

  handleShow = (e) => {
    const { value } = e.target;
    switch (e.target.name) {
      case "create":
        this.setState({
          showCreateEdit: true,
          itemId: "",
        });
        break;
      case "edit":
        this.setState({
          showCreateEdit: true,
          itemId: value,
        });
        break;
      case "delete":
        this.setState({ showDelete: true, itemId: value });
        break;
      default:
        break;
    }
  };

  handleHide = (e) => {
    this.setState({
      showCreateEdit: false,
      showDelete: false,
    });
  };

  handleCreate = (data) => {
    this.getProjects();
    // const projects = this.state.projects.concat(data);
    // this.setState({
    //   projects,
    // });
  };

  handleEdit = (id, data) => {
    this.getProjects();
    // let projects = [...this.state.projects];
    // const index = projects.findIndex((m) => m.id === id);
    // projects[index] = data;
    // this.setState({
    //   projects,
    // });
  };

  handleDelete = (id) => {
    Axios.delete("/api/projects/" + id)
      .then((res) => {
        this.getProjects();
        // let projects = this.state.projects.filter((m) => m.id !== id);
        // this.setState({
        //   projects,
        // });
      })
      .catch((err) => alert(err));
  };

  componentDidMount() {
    this.getProjects();
  }

  onChangeToggle = (e) => {
    if (this.props.auth.user.role === roleTypes.employee) {
      return;
    }

    let state = { ...this.state };

    state.availableProjects = false;
    state.soldProjects = false;

    state[e.target.id] = e.target.checked;
    this.setState(state, () => {
      this.setState({
        projectsSorted: this.state.availableProjects
          ? this.state.projects.filter((m) => m.bookings < 1)
          : this.state.projects.filter((m) => m.bookings > 0),
      });
    });
  };

  getActions() {
    const isEmployee = this.props.auth.user.role === roleTypes.employee;

    let actions = [];

    if (!isEmployee) {
      actions.push({
        icon: "edit",
        tooltip: "Edit",
        onClick: (event, rowData) => {
          this.handleShow({
            target: { name: "edit", value: rowData.id },
          });
        },
      });
      actions.push({
        icon: "delete",
        tooltip: "Delete",
        onClick: (event, rowData) => {
          this.handleShow({
            target: { name: "delete", value: rowData.id },
          });
        },
      });
    }

    if (this.state.soldProjects) {
      actions = [
        {
          icon: "paid",
          tooltip: "Bookings",
          onClick: (event, rowData) => {
            this.props.history.push(`project-bookings/${rowData.id}`);
          },
        },
      ];
    }
    return actions;
  }

  render() {
    const isEmployee = this.props.auth.user.role === roleTypes.employee;
    return (
      <div className="container mt-3">
        <h2 className="text-center h2">Properties Master Data</h2>
        <div>
          <Button
            name="create"
            onClick={this.handleShow}
            variant="primary"
            disabled={this.state.loading || isEmployee}
          >
            Add +
          </Button>
          <div
            className="pull-right"
            style={{
              display: "flex",
            }}
          >
            <fieldset className="ml-3 mr-3" disabled={isEmployee}>
              <input
                id="availableProjects"
                name="saleToggle"
                type="radio"
                checked={this.state.availableProjects}
                onChange={this.onChangeToggle}
              />
              <label className="ml-1">Available</label>
              <input
                className="ml-3"
                id="soldProjects"
                name="saleToggle"
                type="radio"
                checked={this.state.soldProjects}
                onChange={this.onChangeToggle}
              />
              <label className="ml-1">Sold</label>
            </fieldset>
          </div>
          <CreateEditProject
            id={this.state.itemId}
            show={this.state.showCreateEdit}
            onHide={this.handleHide}
            onCreate={this.handleCreate}
            onEdit={this.handleEdit}
          />
          <ConfirmDelete
            id={this.state.itemId}
            show={this.state.showDelete}
            onHide={this.handleHide}
            onDelete={this.handleDelete}
          />
          <div className="mt-3" />
          <MaterialTable
            title=""
            isLoading={this.state.loading}
            data={this.state.projectsSorted}
            columns={[
              {
                title: "Type",
                field: "projectType",
              },
              {
                title: "Location",
                field: "location",
              },
              {
                title: "Floor",
                field: "floor",
              },
              {
                title: "Unit Number",
                field: "unitNumber",
              },
              {
                title: "Unit Type",
                field: "unitType",
              },
              {
                title: "Unit Size",
                field: "unitSize",
              },
              {
                title: "Unit Price",
                field: "unitPrice",
                render: (rowData) => formatNumberWithCommas(rowData.unitPrice),
              },
              {
                title: "Reservation Fee",
                field: "reservationFee",
                render: (rowData) =>
                  formatNumberWithCommas(rowData.reservationFee),
              },
              {
                title: "Price (per sqm)",
                field: "pricePerUnit",
                render: (rowData) =>
                  formatNumberWithCommas(rowData.pricePerUnit),
              },
              {
                title: "Installments",
                field: "installments",
              },
              {
                title: "Availability",
                field: "availability",
                render: (rowData) =>
                  rowData.bookings < 1 ? (
                    <p
                      style={{
                        color: "white",
                        background: "MediumSeaGreen",
                        textAlign: "center",
                      }}
                    >
                      Available
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "white",
                        background: "Tomato",
                        textAlign: "center",
                      }}
                    >
                      Sold
                    </p>
                  ),
              },
              {
                title: "Bookings",
                field: "bookings",
                hidden: true,
                export: true,
              },
              {
                title: "Interested Customers",
                field: "interestedCustomers",
                hidden: true,
                export: true,
              },
            ]}
            actions={this.getActions()}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 50, 100],
              exportButton: {
                csv: true,
              },
              exportAllData: true,
              actionsColumnIndex: -1,
              filtering: true,
            }}
          ></MaterialTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProjectsList);
